 export const contacts = {
  "title": "Contacts",
  "subtitle": "TECHLINE",
  "info": {
    "text1": "Legal address:  c. Arquimedes 43, 28946, Fuenlabrada, Madrid, Spain",
    "text2": "Registration nr: B55413538",
    "text3": "VAT nr: ESB55413538",
    "text4": "Email: b2b@techlines.es",
    "text5": "Phone nr: +34 627 575 578"
  }
}