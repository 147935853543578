import style from './editProductForm.module.scss';

export const editProductFormFields = [
  {
    tagType: 'enabled',
    label: "Enabled",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "enabled",
    name: "enabled",
    type: "select",
    options: ["true", "false"],
  },
  {
    tagType: 'button',
    label: "Add image",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "file",
    name: "imageUrls",
    type: "file",
    placeholder: "Enter image URLs",
    multiple: true,
},
  {
    tagType: 'regular',
    label: "Quantity",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "quantity",
    name: "quantity",
    type: "number",
    placeholder: "Enter quantity",
  },
  {
    tagType: 'regular',
    label: "ID",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "_id",
    name: "_id",
    type: "text",
    placeholder: "Enter ID",
  },
  {
    tagType: 'regular',
    label: "Name",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "name",
    name: "name",
    type: "text",
    placeholder: "Enter name",
  },
  {
    tagType: 'regular',
    label: "Current Price",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "currentPrice",
    name: "currentPrice",
    type: "number",
    placeholder: "Enter current price",
  },
  {
    tagType: 'select',
    label: "Categories",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "categories",
    name: "categories",
    type: "select",
    // options: ["animals","art side", "celebrities","cute planet","dog","fantasy landscape","kooler","metaverse","rainbow lion", "other"],
  },
  {
    tagType: 'regular',
    label: "Color",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "color",
    name: "color",
    type: "text",
    placeholder: "Enter color",
  },
  {
    tagType: 'regular',
    label: "ProductUrl",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "productUrl",
    name: "productUrl",
    type: "text",
    placeholder: "Enter productUrl",
  },
  {
    tagType: 'select',
    label: "Brand",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "brand",
    name: "brand",
    type: "select",
    // options: ["animals","art side", "celebrities","cute planet","dog","fantasy landscape","kooler","metaverse","rainbow lion", "other"],
  },
  {
    tagType: 'regular',
    label: "Memory",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "memory",
    name: "memory",
    type: "text",
    placeholder: "Enter memory size",
  },
  {
    tagType: 'regular',
    label: "Type",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "type",
    name: "type",
    type: "text",
    placeholder: "Enter type",
  },
  {
    tagType: 'regular',
    label: "EAN",
    labelClass: style.form__label,
    inputClass: style.form__input,
    errorClass: style.form__error,
    id: "ean",
    name: "ean",
    type: "text",
    placeholder: "Enter EAN",
  },
];